import WordingProvider from "../../clients/wording/WordingProvider.ts";
import BankDetailsRequest from "../entities/BankDetailsRequest.ts";
import Account from "../entities/Account.ts";
import NotaryHolder from "../entities/NotaryHolder.ts";
import { AccountType } from "../entities/AccountType.ts";

export function getBankDetailsReminderBody(arg0: {
  OFFICE_NAME: string;
  COLLABORATOR_FULL_NAME: string;
  FIRST_NAME: string;
  SPACE_IF_FIRST_NAME_EXIST: string;
}) {
  return injectVariables({
    rawString: getText("bank_details_request_reminder_email_body"),
    variables: arg0,
  });
}
export function getBankDetailsReminderSubject() {
  return getText("bank_details_request_reminder_email_subject");
}
export function getFirstAuthEmailBody(variables: {
  FIRST_NAME: string;
  LAST_NAME: string;
  CODE: string;
}) {
  return injectVariables({
    rawString: getText("first_auth_email_body"),
    variables,
  });
}
export function getFirstAuthEmailSubject(variables: { FIRST_NAME: string }) {
  return injectVariables({
    rawString: getText("first_auth_email_subject"),
    variables,
  });
}
export function injectVariables({
  rawString,
  variables,
}: {
  rawString: string;
  variables: Record<string, string>;
}) {
  return rawString.replace(
    /\${(.*?)}/g,
    (_, key: keyof typeof variables) => variables[key] ?? `$\{${key}}`
  );
}

export function getText(key: string) {
  return WordingProvider.shared.getText(key);
}
export function getBankDetailsCreationEmailSubject({
  bankDetailsRequest,
}: {
  bankDetailsRequest: BankDetailsRequest;
}) {
  if (!bankDetailsRequest.caseFile?.name)
    throw new Error(
      "Missing Casefile name while producing bank_details_creation_email_subject"
    );
  return injectVariables({
    rawString: getText("bank_details_creation_email_subject"),
    variables: {
      CASE_FILE_NAME: bankDetailsRequest.caseFile.name,
    },
  });
}

export function getBankDetailsCreationEmailBody({
  clientUrl,
  bankDetailsRequest,
}: {
  clientUrl: string;
  bankDetailsRequest: BankDetailsRequest;
}) {
  if (!bankDetailsRequest.accounts)
    throw new Error(
      "Missing accounts while producing bank_details_creation_email_body"
    );
  if (!bankDetailsRequest.caseFile?.name)
    throw new Error(
      "Missing Casefile name while producing bank_details_creation_email_subject"
    );
  return injectVariables({
    rawString: getText("bank_details_creation_email_body"),
    variables: {
      CLIENT_URL: clientUrl,
      CASE_FILE_NAME: bankDetailsRequest.caseFile.name,
      BANK_DETAILS_REQUEST_MAILTO_REQUEST_BREAKDOWN: bankDetailsRequest.accounts
        .map((account) => getBankDetailsCreationEmailBodyBreakdown({ account }))
        .join("\n"),
    },
  });
}

function getBankDetailsCreationEmailBodyBreakdown({
  account,
}: {
  account: Account;
}) {
  if (!account.holders)
    throw new Error(
      "Missing holders while producing bank_details_creation_email_body_account_line"
    );
  if (account.holders.some((holder) => !(holder instanceof NotaryHolder)))
    throw new Error(
      "There should be only NotaryHolder while producing bank_details_creation_email_body_account_line"
    );
  return injectVariables({
    rawString: getText("bank_details_creation_email_body_account_line"),
    variables: {
      ACCOUNT_TYPE:
        account.getType() === AccountType.SINGLE_HOLDER
          ? getText("bank_details_request_dialog_single_person_account_chip")
          : account.getType() === AccountType.MULTIPLE_HOLDERS
          ? getText("bank_details_request_dialog_multiple_person_account_chip")
          : getText("bank_details_request_dialog_company_account_chip"),
      HOLDER_NAME: account.holders
        .map((holder) => (holder as NotaryHolder).issuer.name)
        .join(" et "),
    },
  });
}

export function getCollaboratorBankDetailsRequestNotificationSubject(
  variables: Record<string, string>
) {
  return injectVariables({
    rawString: getText(
      "bank_details_data_available_collaborator_email_subject"
    ),
    variables,
  });
}

export function getCollaboratorBankDetailsRequestNotificationBody(
  variables: Record<string, string>
) {
  return injectVariables({
    rawString: getText("bank_details_data_available_collaborator_email_body"),
    variables,
  });
}
